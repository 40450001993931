import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Grid,
  Collapse,
  Typography,
  Box,
  TextField,
} from "@material-ui/core";
import { getFormatedNow } from "../../../utils/dates";
import request from "../../../requests/request";
import { getInformeMovimientoStock } from "../../../requests/urls";
import { validateFilters } from "../../../components/ait-reusable/Filtros/utils";
import getArticulosProv, { formatMovimientos } from "./utils";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import axios from "axios";
import { errorNotification } from "../../../components/Notifications";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import { getFilters } from "../../Proveedores/Catalogo/utils";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import {
  updateProveedor,
  addArticulo,
  addSomeArticles,
  vaciarCart,
} from "../../../Redux/Actions/pedidoActions";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import ButtonFilter from "../../../components/ait-reusable/Button/ButtonFilter";
import ModalAgregarAPedido from "./Components/ModalAgregarAPedido";
import Filtros from "./Filtros";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import { useDebounce } from "../../../customHooks/useDebounce";
import { useFiltroStock } from "../../../customHooks/useFiltroStock";
import useArticulosSucursal from "../../../customHooks/useGetArticulosSucursal";

const MovimientoStock = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { idProveedor, idRepuestoSucursal } = useParams();
  const { pedidos, dataForm } = useSelector((state) => state.pedido);
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [dates, setDates] = useState({
    fromDate: getFormatedNow(),
    toDate: getFormatedNow(),
  });
  const [moreFilters, setMoreFilters] = useState({
    proveedor: null,
    entrada: null,
    motivo: null,
    origen: [],
  });
  const [listfilters, setListfilters] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [period, setPeriod] = useState("today");
  const [movimientos, setMovimientos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [articulosSelected, setArticulosSelected] = useState([]);
  const [proveedorSelected, setProveedorSelected] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [openModalPedido, setOpenModalPedido] = useState(false);
  const [useDefaultArticle, setUseDefaultArticle] = useState(false);
  const [useDefaultProv, setUseDefaultProv] = useState(false);
  const [article, setArticle] = useState(null);

  const { dataArticulos } = useArticulosSucursal({
    listfilters,
    useMeli: false,
    proveedorSelect: "0",
  });

  const {
    loading: loadingArticulos,
    articulos,
    getArticulosSucursal,
    filters,
  } = dataArticulos;

  const {
    checkedArt,
    soloStock,
    sinStock,
    tieneMeli,
    filter,
    busquedaArticulo,
    debouncedSearchTerm,
    setCheckedArt,
    setSoloStock,
    setSinStock,
    setTieneMeli,
    setFilter,
    setBusqueda,
  } = filters;

  const {
    handleChangeArt,
    handleChangeTodosMisArt,
    handleChangeSoloStock,
    handleChangeSinStock,
    handleChangeConVinculacionMeli,
  } = useFiltroStock(setCheckedArt, setSoloStock, setSinStock, setTieneMeli);
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const [observacionValue, setObservacionValue] = useState("");

  const debouncedValueObservacion = useDebounce(observacionValue, 500);
  let columns = [
    "Fecha",
    "E/S",
    "Artículo",
    "Auxiliar",
    "Motivo",
    "Origen",
    "Responsable",
    "Usuario",
    "Observaciones",
    "Descripción",
    "Proveedor",
    "Cantidad",
    "Stock Deseado",
    "Stock Histórico",
    "Stock Actual",
    "Stock a Pedir",
    "Estado",
  ];

  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  const getMovimientos = async () => {
    setLoading(true);
    const proveedorFilter = moreFilters.proveedor
      ? moreFilters.proveedor.idProveedor
      : null;
    try {
      const response = await request({
        method: "GET",
        url: getInformeMovimientoStock,
        params: {
          from_date:
            period === "custom"
              ? moment(dates.fromDate).format("YYYY-MM-DD-HH:mm:ss")
              : dates.fromDate,
          to_date:
            period === "custom"
              ? moment(dates.toDate).format("YYYY-MM-DD-HH:mm:ss")
              : dates.toDate,
          page: pagination.page,
          articulos: useDefaultArticle
            ? [idRepuestoSucursal]
            : article
              ? [article.repuesto_sucursal.idRepuestoSucursal]
              : [],
          proveedor: useDefaultProv ? idProveedor : proveedorFilter,
          entrada: moreFilters.entrada,
          responsable: useEmpleado.empleado
            ? useEmpleado.empleado.idEmpleado
            : null,
          observacion: debouncedValueObservacion,
          motivo: moreFilters.motivo === "all" ? null : moreFilters.motivo,
          origen: moreFilters.origen.map((x) => x.id),
        },
      });
      handleResponseMovimientos(response);
    } catch (error) {
      console.error(error);
      clearState();
    }
  };

  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setMovimientos([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  const handleResponseMovimientos = (response) => {
    setProveedorSelected(moreFilters.proveedor);
    if (response.status === 200) {
      let data = response.data.data;
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setMovimientos(data.items);
      setPagination({
        ...pagination,
        num_items: data.num_items,
        next_page: data.next_page,
      });
    } else {
      clearState();
    }
  };

  const descargarListaProductosVendidos = () => {
    const proveedorFilter = moreFilters.proveedor
      ? moreFilters.proveedor.idProveedor
      : null;
    let url = `/api/stock/exportar-vendidos/`;
    let dataArt = article && article.repuesto_sucursal.idRepuestoSucursal;
    let fileName = `Informe_articulos${dataArt ? "_" + dataArt + "_" : "_"}${
      dates.fromDate
    }_${dates.toDate}.xlsx`;
    axios({
      url: url,
      params: {
        desde:
          period === "custom"
            ? moment(dates.fromDate).format("YYYY-MM-DD-HH:mm:ss")
            : dates.fromDate,
        hasta:
          period === "custom"
            ? moment(dates.toDate).format("YYYY-MM-DD-HH:mm:ss")
            : dates.toDate,
        articulos: dataArt ? [dataArt] : [],
        proveedor: useDefaultProv ? idProveedor : proveedorFilter,
        entrada: moreFilters.entrada,
        responsable: useEmpleado.empleado
          ? useEmpleado.empleado.idEmpleado
          : null,
        observacion: debouncedValueObservacion,
        motivo: moreFilters.motivo === "all" ? null : moreFilters.motivo,
        origen: moreFilters.origen.map((x) => x.id),
      },
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Disposition": `attachment; filename=${fileName}`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        link.click();
      })
      .catch((err) => {
        console.log(err);
        errorNotification("Informa no disponible para el periodo seleccionado");
      });
  };

  const validateDates = () => {
    setErrors(validateFilters(dates));
    setIsSubmitting(true);
    setArticulosSelected([]);
  };

  const getListFilters = () => {
    getFilters().then((res) => {
      setListfilters(res);
      res.length > 0 &&
        setFilter(localStorage.getItem("filtroArticulos") || res[0].id);
    });
  };

  const toCompras = async () => {
    if (proveedorSelected !== null && articulosSelected.length > 0) {
      if (dataForm.proveedor === null) {
        dispatch(updateProveedor(proveedorSelected));
        agregarItem();
      } else if (
        dataForm.proveedor.idProveedor !== proveedorSelected.idProveedor
      ) {
        dispatch(vaciarCart());
        dispatch(updateProveedor(proveedorSelected));
        agregarItem(true);
      } else {
        agregarItem();
      }
    }
  };

  const agregarItem = (vaciar) => {
    let array_id = [];
    articulosSelected.map(
      (x) => x.idRepuestoProveedor && array_id.push(x.idRepuestoProveedor),
    );
    // Codigo anterior
    // array_id.length > 0 &&
    //   getArticulosProv(proveedorSelected.idProveedor, array_id).then(
    //     (response) => {
    //       console.log(response, "response");
    //       const articulosFormateados = articulosSelected.map((p) => ({
    //         ...p,
    //         codProveedor: p.codigo,
    //         descripcionProveedor: p.desc_articulo,
    //       }));
    //       console.log(articulosFormateados, "articulosFormateados");
    //       response && response.length > 1
    //         ? dispatch(addSomeArticles(vaciar ? [] : pedidos, response))
    //         : dispatch(addArticulo(vaciar ? [] : pedidos, response[0]));
    //     }
    //   );

    if (articulosSelected.length > 1) {
      const articulosFormateados = articulosSelected.map((p) => ({
        ...p,
        codProveedor: p.codigo,
        descripcionProveedor: p.desc_articulo,
        is_deleted: false,
      }));
      dispatch(addSomeArticles(vaciar ? [] : pedidos, articulosFormateados));
    } else {
      dispatch(
        addArticulo(vaciar ? [] : pedidos, {
          ...articulosSelected[0],
          codProveedor: articulosSelected[0].codigo,
          descripcionProveedor: articulosSelected[0].desc_articulo,
        }),
      );
    }
    history.push("/proveedores/pedidos/");
  };

  const onSelectAllClick = () => {
    if (movimientos.length === articulosSelected.length)
      setArticulosSelected([]);
    else setArticulosSelected(movimientos);
  };

  const handleChangeSelection = (item) => {
    // if (item.codigo) {
    let exist = undefined;
    articulosSelected.find((x) => {
      if (x.codigo === item.codigo) exist = x;
      else exist = undefined;
    });
    if (articulosSelected.find((x) => x.codigo === item.codigo)) {
      /**existe */
      let equivalent = movimientos.find((x) => x.codigo === item.codigo);
      let index = articulosSelected.indexOf(
        articulosSelected.find((x) => x.codigo === equivalent.codigo),
      );
      let newSelection = [...articulosSelected];
      newSelection.splice(index, 1);
      setArticulosSelected(newSelection);
    } else {
      /**nuevo */
      setArticulosSelected([
        ...articulosSelected,
        movimientos.find((x) => x.codigo === item.codigo),
      ]);
    }
    // } else return null;
  };

  const isDisabled = (item) => (item.codigo !== "---" ? false : true);

  const handleOpenModal = () => {
    setOpenModalPedido(true);
  };

  const handleCloseModalPedido = () => {
    setOpenModalPedido(false);
  };

  useEffect(() => {
    getArticulosSucursal();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getMovimientos();
  }, [pagination.page, debouncedValueObservacion]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      pagination.page !== 1
        ? setPagination({ ...pagination, page: 1 })
        : getMovimientos();
    }
  }, [errors]);

  useEffect(() => {
    getListFilters();

    let value = localStorage.getItem("filtroMostrar");

    if (value) {
      switch (Number(value)) {
        case 1:
          handleChangeArt(true);
          break;
        case 2:
          handleChangeTodosMisArt(true);
          break;
        case 3:
          handleChangeSoloStock(true);
          break;
        case 4:
          handleChangeSinStock(true);
          break;
        case 5:
          handleChangeConVinculacionMeli(true);
        default:
          break;
      }
    }
  }, []);

  return (
    <Grid className="main-content-container px-4">
      <PageHeader
        title={"Informe MOVIMIENTOS STOCK"}
        subtitle={"STOCK"}
        history={props.history}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonFilter click={() => setCollapseOpen(!collapseOpen)} />
          <Grid container spacing={2} style={{ paddingTop: 8 }}>
            <Grid item xs={12}>
              <Collapse in={collapseOpen}>
                <Filtros
                  setProveedorSelected={setProveedorSelected}
                  useDefaultProv={useDefaultProv}
                  setUseDefaultProv={setUseDefaultProv}
                  useDefaultArticle={useDefaultArticle}
                  setUseDefaultArticle={setUseDefaultArticle}
                  idProveedor={idProveedor}
                  idRepuestoSucursal={idRepuestoSucursal}
                  period={period}
                  setPeriod={setPeriod}
                  dates={dates}
                  setDates={setDates}
                  clearState={clearState}
                  errors={errors}
                  setErrors={setErrors}
                  optionsPeriod={optionsPeriod}
                  dataArticulo={{
                    configGeneral: configGeneral,
                    loading: loadingArticulos,
                    articulos: articulos,
                    article: article,
                    setArticle: setArticle,
                    busquedaArticulo: busquedaArticulo,
                    setBusqueda: setBusqueda,
                  }}
                  dataBuscador={{
                    listfilters: listfilters,
                    filter: filter,
                    setFilter: setFilter,
                  }}
                  dataFiltros={{
                    setMoreFilters: setMoreFilters,
                    moreFilters: moreFilters,
                  }}
                  validateDates={validateDates}
                  useEmpleado={useEmpleado}
                  useQueryEmpleado={useQueryEmpleado}
                  checks={{
                    soloStock,
                    sinStock,
                    checkedArt,
                    tieneMeli,
                  }}
                  handleChangeFiltro={{
                    handleChangeArt,
                    handleChangeTodosMisArt,
                    handleChangeSoloStock,
                    handleChangeSinStock,
                    handleChangeConVinculacionMeli,
                  }}
                />
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={"mb-2 pb-4"}>
        <Grid item xs={12} md={12} lg={12}>
          <ReportTable
            tipo={"iStock"}
            checkbox={
              proveedorSelected
                ? {
                    numSelected: articulosSelected,
                    rowCount: movimientos,
                    onSelectAllClick: onSelectAllClick,
                    checked: (item) =>
                      articulosSelected.find((x) => x.codigo === item.codigo)
                        ? true
                        : false,
                    onChange: handleChangeSelection,
                    disabled: isDisabled,
                  }
                : undefined
            }
            items={formatMovimientos(movimientos)}
            columns={columns}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
            itemsCountPerPage={15}
          >
            <Grid
              container
              spacing={2}
              style={{ marginBottom: 5 }}
              alignContent="center"
              alignItems="center"
              className={"d-flex justify-content-start"}
            >
              <Grid item xs={12}>
                <Typography variant="h6">
                  {`Movimientos desde 
                  ${
                    dates.fromDate &&
                    moment(dates.fromDate).format("DD-MM-YYYY")
                  } 
                  al ${
                    dates.toDate && moment(dates.toDate).format("DD-MM-YYYY")
                  } `}
                </Typography>
                {proveedorSelected && (
                  <span
                    style={{
                      fontSize: 14,
                      color: "#000000a3",
                    }}
                  >{`Proveedor: ${proveedorSelected.razonSocial}`}</span>
                )}
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Buscar por observación."
                  size="small"
                  value={observacionValue}
                  onChange={(e) => setObservacionValue(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <div
                  style={{
                    justifyContent: "flex-end",
                    display: "inline-flex",
                    width: "100%",
                  }}
                >
                  <div style={{ paddingTop: 3, paddingRight: 3 }}>
                    <TooltipMoreInfoMaterial
                      position={"left"}
                      titleTooltip={
                        <h6
                          style={{
                            fontSize: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          {
                            "Para hacer un pedido primero debe realizar una consulta por proveedor y luego podrá elegir los artículos que desea agregar."
                          }
                        </h6>
                      }
                      color={"#000"}
                    />
                  </div>

                  <Button
                    onClick={toCompras}
                    style={{ fontWeight: "bold", outline: "none" }}
                    disableElevation
                    variant="contained"
                    color={"primary"}
                    size="small"
                    disabled={
                      articulosSelected.length > 0 && proveedorSelected
                        ? false
                        : true
                    }
                    startIcon={<ShoppingBasketIcon />}
                  >
                    hacer pedido
                  </Button>
                  <Box style={{ marginLeft: 5 }}>
                    <Button
                      onClick={handleOpenModal}
                      style={{ fontWeight: "bold", outline: "none" }}
                      disableElevation
                      variant="contained"
                      color={"primary"}
                      size="small"
                      disabled={
                        articulosSelected.length > 0 && proveedorSelected
                          ? false
                          : true
                      }
                      startIcon={<ControlPointIcon />}
                    >
                      Agregar a pedido
                    </Button>
                  </Box>

                  <div style={{ marginLeft: 5 }}>
                    <Button
                      onClick={descargarListaProductosVendidos}
                      style={{ fontWeight: "bold", outline: "none" }}
                      disableElevation
                      variant="contained"
                      size="small"
                      startIcon={<GetAppIcon />}
                    >
                      INFORME DE ARTICULOS
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </ReportTable>
        </Grid>
      </Grid>
      {openModalPedido && (
        <ModalAgregarAPedido
          open={openModalPedido}
          close={handleCloseModalPedido}
          articulosSelected={articulosSelected}
          proveedorSelected={proveedorSelected}
        />
      )}
    </Grid>
  );
};

export default MovimientoStock;
